angular.module('NosotrosModule')
.directive('nosotrosCabecera',()=>{
  return{
    templateUrl:'/views/nosotros/nosotros-cabecera.html'
  }
})
.directive('nosotrosInfo',()=>{
  return{
    templateUrl:'/views/nosotros/nosotros-info.html'
  }
})
.directive('nosotrosTimeline',()=>{
  return{
    templateUrl:'/views/nosotros/nosotros-timeline.html'
  }
})