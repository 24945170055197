angular.module('ProductosModule')
.directive('productosCabecera',()=>{
  return{
    templateUrl:'/views/productos/productos-cabecera.html'
  }
})
.directive('productosDescripcion',()=>{
  return{
    templateUrl:'/views/productos/productos-descripcion.html'
  }
})
.directive('productosCaracteristicas',()=>{
  return{
    templateUrl:'/views/productos/productos-caracteristicas.html'
  }
})
.directive('formularioConsulta',()=>{
  return{
    templateUrl:'/views/productos/formulario-consulta.html'
  }
})
.directive('galeriaImagenes',()=>{
  return{
    templateUrl:'/views/productos/galeria-imagenes.html'
  }
})