angular.module("HomeModule", []).controller("home.index", ($scope, $timeout) => {


  $scope.puntero = 2;
  $scope.trailers = [{
      fondo: '/assets/images/kiter-01.jpg',
      producto: "HABITACIONAL",
      ref: "habitacional",
      textoproducto: "Su espacio de descanso y confort"
    },
    {
      fondo: '/assets/images/kiter-02.jpg',
      producto: "OFICINAS",
      ref: "oficinas",
      textoproducto: "lleve su oficina esté donde esté"
    },
    {
      fondo: '/assets/images/kiter-03.jpg',
      producto: "COMPANY MAN",
      ref: "companyman",
      textoproducto: " El espacio adecuado para su cliente"
    },
  ];
  $scope.toggleFade = false;

  let cambiarPortada = (indice) => {
    $timeout(() => {
      $scope.toggleFade = true;
      $timeout(() => {
        $scope.puntero = indice;
        $scope.toggleFade = false;
        cambiarPortada(indice === 0 ? 2 : indice - 1);
      });
    }, 5000);
  };
  cambiarPortada(1);
});