angular
  .module('NosotrosModule', [])
  .controller("nosotros.index", NosotrosController);

function NosotrosController($scope, $http) {
  $scope.timelineactivo=0;
  $scope.timeline = [];
  $http.get('/assets/data/resources.json').then(
    ({
      data
    }) => {
      $scope.timeline = data.timeline;
    },
    error => {
      console.error(error);
    }
  );
  $scope.activar=(index)=>{
    $scope.timelineactivo=index;
  };
  $scope.desactivar=()=>{
    $scope.timelineactivo=0;
  };
}

