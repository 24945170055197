angular
  .module("MainApp")
  .config(($httpProvider, $routeProvider, $locationProvider) => {
    $httpProvider.defaults.headers.common["X-Requested-With"] =
      "XMLHttpRequest";

    $locationProvider.html5Mode(true);

    $routeProvider
      .when("/", {
        templateUrl: "/views/home/index.html",
        controller: "home.index"
      })
      .when("/nosotros", {
        templateUrl: "/views/nosotros/index.html",
        controller: "nosotros.index"
      })
      .when("/productos", {
        templateUrl: "/views/productos/index.html",
        controller: "productos.index"
      })
      .when("/productos/:producto", {
        templateUrl: "/views/productos/index.html",
        controller: "productos.index"
      })
      .otherwise({
        templateUrl: "/views/home/index.html",
        redirectTo: "/"
      });
  });
