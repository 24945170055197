"use strict";

angular.module("MainApp", ["ngRoute", "ngTouch", "HomeModule", "ProductosModule", "NosotrosModule"
//, "angular-google-analytics"
])
// .config(AnalyticsProvider=>{
//   AnalyticsProvider.setAccount('')//EJ UA-3123123-2
// })
.controller("mainController", function ($location, $rootScope, $window, $http
//Analytics
) {
  $rootScope.isMobile = $window.innerWidth <= 768;
  angular.element($window).bind("resize", function () {
    $rootScope.isMobile = $window.innerWidth <= 768;
    $rootScope.$$phase || $rootScope.$apply();
  });
  $rootScope.enProductos = false;
  $rootScope.$on("$routeChangeSuccess", function () {
    var bodyClass = "";
    // Analytics.trackPage($location.$$path);
    var path = $location.$$path.split("/");
    path.map(function (tramo) {
      if (tramo !== "") {
        bodyClass += "-".concat(tramo);
      }
    });
    $rootScope.bodyClass = bodyClass !== "" ? "page".concat(bodyClass) : "page-home";
    $rootScope.enProductos = bodyClass === '-productos' || bodyClass === '-nosotros' || bodyClass.indexOf('productos') >= 0;
  });
  $rootScope.contactenos = false;
  $rootScope.abrirContactenos = function () {
    $rootScope.contactenos = true;
  };
  $rootScope.cerrarContactenos = function () {
    $rootScope.contactenos = false;
  };
}).filter("precio", function () {
  return function (input) {
    if (input) {
      return parseFloat(input).toFixed(2);
    }
    return "";
  };
}).filter("encodeurl", function () {
  return function (input) {
    if (input) {
      return window.encodeURIComponent(input);
    }
    return "";
  };
}).filter("removeTrailingSlash", function () {
  return function (input) {
    if (input) {
      if (input[0] === "/") return input.slice(1);else return input;
    }
    return "";
  };
});
"use strict";

angular.module("MainApp").directive('contactenos', function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/contactenos.html",
    controller: "general.contactenos"
  };
}).directive("mainMenu", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/main-menu.html",
    controller: "general.main-menu"
  };
}).directive("mobileMenu", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/mobile-menu.html",
    controller: "general.mobile-menu"
  };
}).directive("footerSection", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/footer-section.html",
    controller: "general.footer"
  };
}).directive("loadFrame", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $("document").ready(function () {
        $(elm).attr("src", attrs.source);
      });
    }
  };
}).directive("scrollRight", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "+=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollLeft", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "-=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollToContacto", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $("footer-section").offset().top
        }, 500);
      });
    }
  };
}).directive("scrollToTarget", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $(attrs.target).offset().top
        }, 700);
      });
    }
  };
}).directive("escondeMenu", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("#navbar").collapse("hide");
      });
    }
  };
}).directive("flotante", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(window).on("scroll", function (e) {
        if ($(window).scrollTop() > 50) {
          // [TBM]
          if ($(window).width() <= 992) {
            // [TBM]
            $(elm).addClass("flotante");
          }
        } else {
          $(elm).removeClass("flotante");
        }
      });
    }
  };
}).directive("colorSvg", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      elm[0].addEventListener("load", function () {
        var paths = this.getSVGDocument().querySelectorAll("path");
        for (var i = 0; i < paths.length; i++) {
          paths[i].setAttribute("style", "fill: ".concat(attrs.colorSvg));
        }
      });
    }
  };
});
"use strict";

angular.module("MainApp").config(function ($httpProvider, $routeProvider, $locationProvider) {
  $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  $locationProvider.html5Mode(true);
  $routeProvider.when("/", {
    templateUrl: "/views/home/index.html",
    controller: "home.index"
  }).when("/nosotros", {
    templateUrl: "/views/nosotros/index.html",
    controller: "nosotros.index"
  }).when("/productos", {
    templateUrl: "/views/productos/index.html",
    controller: "productos.index"
  }).when("/productos/:producto", {
    templateUrl: "/views/productos/index.html",
    controller: "productos.index"
  }).otherwise({
    templateUrl: "/views/home/index.html",
    redirectTo: "/"
  });
});
"use strict";

angular.module("HomeModule", []).controller("home.index", function ($scope, $timeout) {
  $scope.puntero = 2;
  $scope.trailers = [{
    fondo: '/assets/images/kiter-01.jpg',
    producto: "HABITACIONAL",
    ref: "habitacional",
    textoproducto: "Su espacio de descanso y confort"
  }, {
    fondo: '/assets/images/kiter-02.jpg',
    producto: "OFICINAS",
    ref: "oficinas",
    textoproducto: "lleve su oficina esté donde esté"
  }, {
    fondo: '/assets/images/kiter-03.jpg',
    producto: "COMPANY MAN",
    ref: "companyman",
    textoproducto: " El espacio adecuado para su cliente"
  }];
  $scope.toggleFade = false;
  var cambiarPortada = function cambiarPortada(indice) {
    $timeout(function () {
      $scope.toggleFade = true;
      $timeout(function () {
        $scope.puntero = indice;
        $scope.toggleFade = false;
        cambiarPortada(indice === 0 ? 2 : indice - 1);
      });
    }, 5000);
  };
  cambiarPortada(1);
});
"use strict";

angular.module('NosotrosModule', []).controller("nosotros.index", NosotrosController);
function NosotrosController($scope, $http) {
  $scope.timelineactivo = 0;
  $scope.timeline = [];
  $http.get('/assets/data/resources.json').then(function (_ref) {
    var data = _ref.data;
    $scope.timeline = data.timeline;
  }, function (error) {
    console.error(error);
  });
  $scope.activar = function (index) {
    $scope.timelineactivo = index;
  };
  $scope.desactivar = function () {
    $scope.timelineactivo = 0;
  };
}
"use strict";

angular.module("ProductosModule", []).controller("productos.index", ProductosController);
function ProductosController($scope, $routeParams, $http, $rootScope) {
  var prod = $routeParams.producto;
  $scope.productos = [];
  $scope.galeria = [];
  $scope.producto = {};
  $scope.offset = 0;
  $scope.offsetMobile = 0;
  $scope.togglefit = false;
  $scope.indice = 0;
  $scope.toggleCover = function () {
    $scope.togglefit = !$scope.togglefit;
  };
  $http({
    method: "GET",
    url: "/assets/data/resources.json"
  }).then(function (_ref) {
    var data = _ref.data;
    $scope.productos = data.productos;
    $scope.producto = $scope.productos.filter(function (producto) {
      return prod === producto.ref;
    })[0];
    $scope.galeria = $scope.producto.galeria;
  }, function (error) {
    console.error(error);
  });
  //===========GALERIA DE IMAGENES

  $scope.fotosAnteriores = function () {
    $scope.indice = $scope.indice === 0 ? $scope.indice : $scope.indice - 1;
  };
  $scope.fotosSiguientes = function () {
    if ($rootScope.isMobile) $scope.indice = $scope.indice + 1 > $scope.galeria.length - 1 ? $scope.indice : $scope.indice + 1;else $scope.indice = ($scope.indice + 1) * 3 > $scope.galeria.length - 1 ? $scope.indice : $scope.indice + 1;
  };
  //===========POPUP IMAGEN==========
  $scope.popupImagenSelecta = 0;
  $scope.activarImagen = false;
  $scope.popupImagen = function (indice) {
    $scope.popupImagenSelecta = indice;
    $scope.activarImagen = true;
  };
  $scope.popupProximaImagen = function () {
    $scope.popupImagenSelecta = $scope.galeria.length - 1 === $scope.popupImagenSelecta ? $scope.popupImagenSelecta : $scope.popupImagenSelecta + 1;
  };
  $scope.popupAnteriorImagen = function () {
    $scope.popupImagenSelecta = $scope.popupImagenSelecta === 0 ? $scope.popupImagenSelecta : $scope.popupImagenSelecta - 1;
  };
  //===========POPUP IMAGEN END==========

  //===========MAILING CONSULTA==========
  $scope.formconsulta = {
    sent: false,
    nombre: "",
    email: "",
    consulta: "",
    solicitado: false,
    envioCorrecto: false
  };
  $scope.enviarconsulta = function (e) {
    e.preventDefault();
    $scope.formconsulta.sent = true;
    $http.post("api/mailing/consulta", {
      nombre: $scope.formconsulta.nombre,
      email: $scope.formconsulta.email,
      consulta: $scope.formconsulta.consulta,
      producto: $scope.producto
    }).then(function (response) {
      $scope.formconsulta.sent = false;
      $scope.formconsulta.solicitado = true;
      $scope.formconsulta.envioCorrecto = true;
    }, function (error) {
      $scope.formconsulta.sent = false;
      $scope.formconsulta.solicitado = true;
      $scope.formconsulta.envioCorrecto = false;
      console.error(error);
    });
  };
  //=============MAILING CONSULTA END=======
}
"use strict";

angular.module('HomeModule').directive('homeCabecera', function () {
  return {
    templateUrl: '/views/home/home-cabecera.html'
  };
}).directive('homeNosotros', function () {
  return {
    templateUrl: '/views/home/home-nosotros.html'
  };
});
"use strict";

angular.module('NosotrosModule').directive('nosotrosCabecera', function () {
  return {
    templateUrl: '/views/nosotros/nosotros-cabecera.html'
  };
}).directive('nosotrosInfo', function () {
  return {
    templateUrl: '/views/nosotros/nosotros-info.html'
  };
}).directive('nosotrosTimeline', function () {
  return {
    templateUrl: '/views/nosotros/nosotros-timeline.html'
  };
});
"use strict";

angular.module('ProductosModule').directive('productosCabecera', function () {
  return {
    templateUrl: '/views/productos/productos-cabecera.html'
  };
}).directive('productosDescripcion', function () {
  return {
    templateUrl: '/views/productos/productos-descripcion.html'
  };
}).directive('productosCaracteristicas', function () {
  return {
    templateUrl: '/views/productos/productos-caracteristicas.html'
  };
}).directive('formularioConsulta', function () {
  return {
    templateUrl: '/views/productos/formulario-consulta.html'
  };
}).directive('galeriaImagenes', function () {
  return {
    templateUrl: '/views/productos/galeria-imagenes.html'
  };
});
"use strict";

angular.module("MainApp").factory("getApiData", function ($http, $rootScope) {
  var getter = function getter(url) {
    return $http({
      method: "GET",
      url: url
    });
  };
  return {};
});
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
angular.module("MainApp").factory("storage", function ($window, $rootScope) {
  return {
    getValue: function getValue(key) {
      if (window.localStorage["@kittersimha:".concat(key)]) {
        if (JSON.parse(window.localStorage["@kittersimha:".concat(key)]).type === "object") {
          return JSON.parse(JSON.parse(window.localStorage["@kittersimha:".concat(key)]).value);
        } else if (JSON.parse(window.localStorage["@kittersimha:".concat(key)]).type === "number") {
          return window.parseInt(JSON.parse(window.localStorage["@kittersimha:".concat(key)]).value);
        } else if (JSON.parse(window.localStorage["@kittersimha:".concat(key)]).type === "boolean") {
          return JSON.parse(window.localStorage["@kittersimha:".concat(key)]).value === "true";
        }
        return JSON.parse(window.localStorage["@kittersimha:".concat(key)]).value;
      }
      return false;
    },
    saveValue: function saveValue(key, value) {
      var type = _typeof(value);
      var val = type === "object" ? JSON.stringify(value) : "".concat(value);
      window.localStorage["@kittersimha:".concat(key)] = JSON.stringify({
        type: type,
        value: val
      });
    },
    removeValue: function removeValue(key) {
      delete window.localStorage["@kittersimha:".concat(key)];
    }
  };
});
"use strict";

angular.module("MainApp").controller("general.main-menu", function () {}).controller("general.contactenos", function ($scope) {}).controller("general.mobile-menu", function ($scope) {
  $scope.open = false;
  $scope.openProducts = false;
}).controller("general.footer", function ($scope, $http) {
  $scope.sent = false;
  $scope.formcontacto = {
    nombre: "",
    telefono: "",
    email: "",
    consulta: "",
    solicitado: false
  };
  $scope.enviar = function (e) {
    e.preventDefault();
    $scope.sent = true;
    $http.post("/api/mailing/contacto", {
      nombre: $scope.formcontacto.nombre,
      telefono: $scope.formcontacto.telefono,
      email: $scope.formcontacto.email,
      consulta: $scope.formcontacto.consulta
    }).then(function (response) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = true;
    }, function (error) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = false;
      console.error(error);
    });
  };
  $scope.formnewsletters = {
    sent: false,
    email: "",
    solicitado: false,
    envioCorrecto: false
  };
  $scope.enviarnewsletters = function (e) {
    e.preventDefault();
    $scope.formnewsletters.sent = true;
    $http.post("/api/mailing/newsletters", {
      email: $scope.formnewsletters.email
    }).then(function (response) {
      $scope.formnewsletters.sent = false;
      $scope.formnewsletters.solicitado = true;
      $scope.formnewsletters.envioCorrecto = true;
    }, function (error) {
      $scope.formnewsletters.sent = false;
      $scope.formnewsletters.solicitado = true;
      $scope.formnewsletters.envioCorrecto = false;
      console.error(error);
    });
  };
});