angular
  .module("MainApp")
  .controller("general.main-menu", () => {
  })
  .controller("general.contactenos", $scope => {
    
  })
  .controller("general.mobile-menu", $scope => {
    $scope.open=false;
    $scope.openProducts=false;
  })
  .controller("general.footer", ($scope, $http) => {
    $scope.sent = false;
    $scope.formcontacto = {
      nombre: "",
      telefono: "",
      email: "",
      consulta: "",
      solicitado: false
    };
    $scope.enviar = (e) => {
      e.preventDefault();
      
      $scope.sent = true;
      $http
        .post("/api/mailing/contacto", {
          nombre: $scope.formcontacto.nombre,
          telefono: $scope.formcontacto.telefono,
          email: $scope.formcontacto.email,
          consulta: $scope.formcontacto.consulta
        })
        .then(
          response => {
            $scope.sent = false;
            $scope.formcontacto.solicitado = true;
            $scope.formcontacto.envioCorrecto = true;
          },
          error => {
            $scope.sent = false;
            $scope.formcontacto.solicitado = true;
            $scope.formcontacto.envioCorrecto = false;
            console.error(error);
          }
        );
    };
    $scope.formnewsletters={
      sent:false,
      email:"",
      solicitado:false,
      envioCorrecto:false
    }
    $scope.enviarnewsletters=(e)=>{
      e.preventDefault();
      
      $scope.formnewsletters.sent=true;
      $http
      .post("/api/mailing/newsletters", {
        email: $scope.formnewsletters.email
      })
      .then(
        response => {
          $scope.formnewsletters.sent=false;
          $scope.formnewsletters.solicitado = true;
          $scope.formnewsletters.envioCorrecto = true;
        },
        error => {
          $scope.formnewsletters.sent = false;
          $scope.formnewsletters.solicitado = true;
          $scope.formnewsletters.envioCorrecto = false;
          console.error(error);
        }
      );
    }
  });
