angular
  .module("ProductosModule", [])
  .controller("productos.index", ProductosController);

function ProductosController($scope, $routeParams, $http, $rootScope) {
  let prod = $routeParams.producto;
  $scope.productos = [];
  $scope.galeria = [];
  $scope.producto = {};
  $scope.offset = 0;
  $scope.offsetMobile = 0;
  $scope.togglefit = false;
  $scope.indice = 0;
  $scope.toggleCover = () => {
    $scope.togglefit = !$scope.togglefit;
  };
  $http({
    method: "GET",
    url: "/assets/data/resources.json"
  }).then(
    ({ data }) => {
      $scope.productos = data.productos;
      $scope.producto = $scope.productos.filter(
        producto => prod === producto.ref
      )[0];
      $scope.galeria = $scope.producto.galeria;
    },
    error => {
      console.error(error);
    }
  );
  //===========GALERIA DE IMAGENES

  $scope.fotosAnteriores = () => {
    $scope.indice = $scope.indice === 0 ? $scope.indice : $scope.indice - 1;
  };
  $scope.fotosSiguientes = () => {
    if ($rootScope.isMobile)
      $scope.indice =
        $scope.indice + 1 > $scope.galeria.length - 1
          ? $scope.indice
          : $scope.indice + 1;
    else
      $scope.indice =
        ($scope.indice + 1) * 3 > $scope.galeria.length - 1
          ? $scope.indice
          : $scope.indice + 1;
  };
  //===========POPUP IMAGEN==========
  $scope.popupImagenSelecta = 0;
  $scope.activarImagen = false;
  $scope.popupImagen = indice => {
    $scope.popupImagenSelecta = indice;
    $scope.activarImagen = true;
  };

  $scope.popupProximaImagen = () => {
    $scope.popupImagenSelecta =
      $scope.galeria.length - 1 === $scope.popupImagenSelecta
        ? $scope.popupImagenSelecta
        : $scope.popupImagenSelecta + 1;
  };
  $scope.popupAnteriorImagen = () => {
    $scope.popupImagenSelecta =
      $scope.popupImagenSelecta === 0
        ? $scope.popupImagenSelecta
        : $scope.popupImagenSelecta - 1;
  };
  //===========POPUP IMAGEN END==========

  //===========MAILING CONSULTA==========
  $scope.formconsulta = {
    sent: false,
    nombre: "",
    email: "",
    consulta: "",
    solicitado: false,
    envioCorrecto: false
  };
  $scope.enviarconsulta = e => {
    e.preventDefault();
    $scope.formconsulta.sent = true;
    $http
      .post("api/mailing/consulta", {
        nombre: $scope.formconsulta.nombre,
        email: $scope.formconsulta.email,
        consulta: $scope.formconsulta.consulta,
        producto: $scope.producto
      })
      .then(
        response => {
          $scope.formconsulta.sent = false;
          $scope.formconsulta.solicitado = true;
          $scope.formconsulta.envioCorrecto = true;
        },
        error => {
          $scope.formconsulta.sent = false;
          $scope.formconsulta.solicitado = true;
          $scope.formconsulta.envioCorrecto = false;
          console.error(error);
        }
      );
  };
  //=============MAILING CONSULTA END=======
}
