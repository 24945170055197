angular
  .module("MainApp", 
  ["ngRoute", 
  "ngTouch", 
  "HomeModule", 
  "ProductosModule", 
  "NosotrosModule"
    //, "angular-google-analytics"
])
  // .config(AnalyticsProvider=>{
  //   AnalyticsProvider.setAccount('')//EJ UA-3123123-2
  // })
  .controller("mainController", 
  ($location, 
    $rootScope, 
    $window, 
    $http
    //Analytics
    ) => {
    $rootScope.isMobile = $window.innerWidth <= 768;
 
    angular.element($window).bind("resize", () => {
      $rootScope.isMobile = $window.innerWidth <= 768;
      $rootScope.$$phase || $rootScope.$apply();
    });
    $rootScope.enProductos = false;
    $rootScope.$on("$routeChangeSuccess", () => {
      let bodyClass = "";
      // Analytics.trackPage($location.$$path);
      let path = $location.$$path.split("/");

      path.map(tramo => {
        if (tramo !== "") {
          bodyClass += `-${tramo}`;
        }
      });

      $rootScope.bodyClass =
        bodyClass !== "" ? `page${bodyClass}` : "page-home";
      $rootScope.enProductos = bodyClass === '-productos' || bodyClass === '-nosotros' || bodyClass.indexOf('productos') >= 0;

    });
    $rootScope.contactenos = false;
    $rootScope.abrirContactenos = () => {
      
      $rootScope.contactenos = true;
    };
    $rootScope.cerrarContactenos = () => {
      $rootScope.contactenos = false;
    };
  })
  .filter("precio", () => {
    return input => {
      if (input) {
        return parseFloat(input).toFixed(2);
      }
      return "";
    };
  })
  .filter("encodeurl", () => {
    return input => {
      if (input) {
        return window.encodeURIComponent(input);
      }
      return "";
    };
  })
  .filter("removeTrailingSlash", () => {
    return input => {
      if (input) {
        if (input[0] === "/") return input.slice(1);
        else return input;
      }
      return "";
    };
  });
