angular.module('HomeModule')
.directive('homeCabecera',()=>{
  return {
    templateUrl:'/views/home/home-cabecera.html',
  }
})
.directive('homeNosotros',()=>{
  return {
    templateUrl:'/views/home/home-nosotros.html',
  }
})